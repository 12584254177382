import EventEmitter from "eventemitter3";
import { TypeDataLangs } from ".";
import { AppBase } from "../appBase";
import { LoaderEvents } from "../loader";

export enum LangsEvents { Changed = "changed" }

export default class Langs extends EventEmitter<LangsEvents> {
    private _currentId: string;
    private _data: TypeDataLangs;
    private _app: AppBase;

    get currentId() { return this._currentId; }
    get lang() { return this._data[this._currentId]; }

    constructor(app: AppBase) {
        super();
        this._app = app;
    }

    init() {
        if (this._app.header.searchParams.get("lang"))
            this.change(this._app.header.searchParams.get("lang"), true, true);
        else if (localStorage.getItem("lang"))
            this.change(localStorage.getItem("lang"), false, true);
        else
            this.change("en", false, true);

        if (this._app.loader && !this._app.loader.disabled) {
            this._app.loader.add('s_data/langs.json', "langs");
            this._app.loader.once(LoaderEvents.Complete, () => {
                this._data = this._app.loader.resources["langs"] as TypeDataLangs;
            });
        }
    }

    change(langId: string, fromSearch: boolean = false, isInit: boolean = false) {
        if (this._currentId == langId && !isInit)
            return;

        const changeLang = () => {
            this._currentId = langId;
            document.documentElement.lang = langId;
            localStorage.setItem("lang", langId);

            if (!fromSearch) {
                this._app.header.setSearchParam("lang", langId);
            }

            const langIdFirstLetterCap = langId.charAt(0).toUpperCase() + langId.slice(1);

            document.querySelectorAll(`a[data-href-${langId}]`).forEach(v => {
                const a = v as HTMLLinkElement;
                a.href = a.dataset[`href${langIdFirstLetterCap}`]
            });

            document.querySelectorAll(`input[data-placeholder-${langId}], textarea[data-placeholder-${langId}]`).forEach(v => {
                const a = v as HTMLInputElement;
                a.placeholder = a.dataset[`placeholder${langIdFirstLetterCap}`]
            });

            this.emit(LangsEvents.Changed);
        }

        if (!isInit) {
            this._app.loader.showUI(true);
            setTimeout(() => changeLang(), 300);
            setTimeout(() => this._app.loader.hideUI(), 500);
        } else
            changeLang()
    }
}