import anime from "animejs";
import { AnimeTarget, configs, IPoint } from ".";
import Langs from "./data/langs";
import Header from "./entities/header";
import Loader from "./loader";

export class AppBase {
    protected _header: Header;
    protected _langs: Langs;
    protected _loader: Loader;
    protected _mousePos: IPoint = { x: -1, y: -1 };

    get header() { return this._header; }
    get langs() { return this._langs; }
    get loader() { return this._loader; }
    get mousePos() { return this._mousePos; }

    constructor() {
        this._langs = new Langs(this);
        this._header = new Header(this);
        this._loader = new Loader();
        this._loader.disabled = true;

        window.addEventListener("load", this._init.bind(this));
    }

    protected _init() {
        document.documentElement.classList.remove("loading");
        this._langs.init();
        this._header.init();

        this._animateDom();

        window.addEventListener("mousemove", e => this._onMouseMove(e));
    }

    protected _animateDom() {
        AppBase.genericAnimateDom("*[data-anim]");
    }

    static genericAnimateDom(targets: AnimeTarget | readonly AnimeTarget[]) {
        return anime({
            targets: targets,
            opacity: [0, 1], translateY: ["4vh", 0], easing: "easeInOutSine",
            duration: configs.animDurPageChildrenShared,
            delay: anime.stagger(75),
        });
    }

    protected _onMouseMove(e: MouseEvent) {
        this._mousePos.x = e.clientX;
        this._mousePos.y = e.clientY;
    }
}