import Langs from "./data/langs";
import Header from "./entities/header";
import Loader from "./loader";

export const TEST_MODE = true; // TODO release false

export const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export type AnimeTarget = string | object | HTMLElement | SVGElement | NodeList | null;

export interface IPoint { x: number; y: number; };
export const appUtils = {
    deg2rad: (deg: number) => deg * Math.PI / 180,
    rad2deg: (rad: number) => rad * 180 / Math.PI,
    dist: (a: number, b: number) => Math.abs(a - b),
    distPoint: (a: { x: number, y: number }, b: { x: number, y: number }) => Math.hypot(a.x - b.x, a.y - b.y),
    lerp: (a: number, b: number, t: number) => (1 - t) * a + t * b,
    toss: (perc: number = .5) => Math.random() < perc,
    clamp: (n: number, min: number, max: number) => Math.min(Math.max(n, min), max),
    rand: (params: { min?: number, max?: number, toInt?: boolean }) => {
        params = params || { min: 0, max: 0 };
        params.min = params.min || 0;
        params.max = params.max || 0;
        const val = Math.random() * (params.max - params.min) + params.min;
        return params.toInt ? Math.floor(val) : val;
    },
    mouseTouchXY(e: MouseEvent | TouchEvent): IPoint {
        return (window.TouchEvent && e instanceof TouchEvent) ?
            { x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY } :
            { x: (e as MouseEvent).clientX, y: (e as MouseEvent).clientY };
    },
    nullOrEmpty(val: any) { return val == null || val == undefined || val == ""; },
    tagsToStrMap: (str: string) => {
        const elms: { [tag: string]: string } = {};
        const tags = str.match(/<template-.*?>([\s\S]*?)<\/template-.*?>/gm);
        if (tags)
            tags.forEach(item => {
                const tagName = item.match(/<template-(.+?)>/)[1];
                elms[tagName] = item.replace(/<(template-.+?|\/template-.+?)>/gm, "").replace(/(^\r\n)|(\r\n$)/g, "");
            });
        return elms;
    },
    stringToHTML: (str: string) => {
        const dv = document.createElement("div");
        dv.innerHTML = str;
        return dv.firstElementChild as HTMLElement;
    },
    dataHrefToHref: (cont: HTMLElement) => {
        cont.querySelectorAll<HTMLAnchorElement>("a[data-href]").forEach(item => item.href = item.dataset["href"]);
    }
};

export const configs = {
    defWidth: 1447,
    defHeight: 760,
    defProductBoxWrapWidth: 570,
    defMapWidth: 1648,
    defMapHeight: 760,

    boxesRotateYDeg: 20,

    productsImagePath: "s_images/products/",
    mapImagePath: "s_images/maps/",

    animDurPageOpacityFade: 400,
    animDurPageChange: 500,
    animDurPageChildrenShared: 400,
    animDurProductChange: 750,
}