import { AppBase } from "../appBase";
import { LangsEvents } from "../data/langs";

export default class Header {
    private _langBtns: NodeListOf<HTMLButtonElement>;
    private _app: AppBase;
    private _langText: HTMLElement;
    private _searchParams: URLSearchParams;
    private _agePropmpt: HTMLElement;

    get searchParams() { return this._searchParams; }

    constructor(app: AppBase) {
        this._app = app;
        this._searchParams = new URLSearchParams(window.location.search);
    }

    init() {
        document.querySelectorAll<HTMLButtonElement>("header button.btn-menu").forEach(item => {
            item.addEventListener("click", () => {
                if (document.body.classList.contains("open-navbar-menu"))
                    document.body.classList.remove("open-navbar-menu");
                else
                    document.body.classList.add("open-navbar-menu");
            });
        });

        document.querySelectorAll<HTMLLIElement>(".navbar-menu__nav>li").forEach(item => {
            item.classList.remove("active");

            const a = item.firstElementChild as HTMLAnchorElement;
            if ((window.location.pathname == "/index.html" && a.pathname == "/") ||
                a.pathname == window.location.pathname)
                item.classList.add("active");
        });

        this._checkForAge();

        this._langText = document.querySelector<HTMLElement>(".navbar-menu__lang .selected-text>span");

        this._langBtns = document.querySelectorAll<HTMLButtonElement>(".navbar-menu__lang button[data-lang]");
        this._langBtns.forEach(item => {
            item.addEventListener("click", () => this._app.langs.change(item.dataset["lang"]));
        });

        this._onLangChange();
        this._app.langs.on(LangsEvents.Changed, this._onLangChange.bind(this));
    }

    setSearchParam(name: string, value: string) {
        this._searchParams.set(name, value);
        window.history.replaceState({}, document.title, "?" + this._searchParams.toString());
    }

    private _checkForAge() {
        this._agePropmpt = document.querySelector<HTMLElement>(".over-18-container");
        if (this._agePropmpt) {
            if (localStorage.getItem("age-granted") == "1") {
                this._agePropmpt.parentElement.removeChild(this._agePropmpt);
                return;
            }
            this._agePropmpt.querySelector<HTMLButtonElement>("button[data-yes]")
                .addEventListener("click", () => {
                    localStorage.setItem("age-granted", "1");
                    this._agePropmpt.parentElement.removeChild(this._agePropmpt);
                });
            this._agePropmpt.querySelector<HTMLButtonElement>("button[data-no]")
                .addEventListener("click", () => {
                    this._agePropmpt.querySelector<HTMLButtonElement>("div[data-prompt]").classList.add('hidden')
                    this._agePropmpt.querySelector<HTMLButtonElement>("div[data-no-result]").classList.remove('hidden')
                });
        }
    }

    private _onLangChange() {
        this._langText.textContent = this._app.langs.currentId.toLocaleUpperCase(this._app.langs.currentId);
        this._langBtns.forEach(item => {
            item.parentElement.classList.remove("selected");
            if (item.dataset["lang"] == this._app.langs.currentId)
                item.parentElement.classList.add("selected");
        });
    }
}