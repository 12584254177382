import { EventEmitter } from "eventemitter3";
import { appUtils } from ".";

export enum LoaderEvents { Progress = "progress", Complete = "complete" }

type File = { name?: string, file: string };

const _MAX_SIM_FILE_COUNT = 4;
export default class Loader extends EventEmitter<LoaderEvents> {
    private _resources: { [id: string]: object | HTMLImageElement } = {};
    private _files: File[];
    private _totalFileLength: number;
    private _loadingQueue: number;
    private _ui: HTMLElement;
    private _timeout: NodeJS.Timeout;
    private _autoHideUI: boolean;

    disabled: boolean = false;

    get resources() { return this._resources; }

    constructor(files: File[] = null) {
        super();
        this._files = files || [];
        this._ui = document.getElementById("loader");
    }

    add(file: string, name: string = null) {
        this._files.push({ file, name });
        return this;
    }

    load(autoHideUI: boolean = true) {
        this._autoHideUI = autoHideUI;
        this._totalFileLength = this._files.length;
        this._loadingQueue = 0;

        this.showUI();

        const l = Math.min(this._totalFileLength, _MAX_SIM_FILE_COUNT);
        for (let i = 0; i < l; i++)
            this._loadItem();

        return this;
    }

    showUI(noWait: boolean = false) {
        if (!this._ui)
            return;

        this._showHideDom(true);

        clearTimeout(this._timeout);
        if (noWait)
            this._ui.style.opacity = "1"
        else if (this._ui.style.opacity != "1")
            this._timeout = setTimeout(() => this._ui.style.opacity = "1", 1000);
        return this;
    }

    private _showHideDom(show: boolean) {
        this._ui.style.visibility = !show ? "hidden" : "visible";
        this._ui.style.zIndex = !show ? "-10" : "1000";
    }

    hideUI() {
        if (!this._ui)
            return;

        clearTimeout(this._timeout);
        if (this._ui.style.opacity == "0")
            this._showHideDom(false);
        else {
            this._ui.style.opacity = "0";
            this._timeout = setTimeout(() => this._showHideDom(false), 500);
        }
        return this;
    }

    private _onComplete() {
        if (this._autoHideUI)
            this.hideUI();
        this._loadingQueue = -1;
        this.emit(LoaderEvents.Complete);
    }

    private _loadItem() {
        let perc: number = 1 - this._files.length / this._totalFileLength;
        this.emit(LoaderEvents.Progress, perc);

        if (!this._files || this._files.length == 0) {
            if (this._loadingQueue == 0)
                this._onComplete();
            return;
        }

        const file = this._files.shift(),
            name = !appUtils.nullOrEmpty(file.name) ? file.name : file.file.match(/^(.+?)(?:\.[^.]*$|$)/)[1];
        if (this._resources[name]) {
            console.warn("duplicate! skipped...", name);
            this._loadItem();
            return;
        }

        this._loadingQueue++;
        const onSuccess = (resource: any) => {
            this._resources[name] = resource;
            this._loadingQueue--;
            this._loadItem();
        };
        const onError = () => {
            console.error(name);
            this._loadingQueue--;
            this._loadItem();
        }

        if (file.file.lastIndexOf(".json") > -1) {
            const req = new XMLHttpRequest();
            req.onload = (e) => {
                try {
                    onSuccess(JSON.parse(req.responseText));
                } catch (e) { console.error(e); onError(); }
            }
            req.onerror = (e) => onError();
            req.open("get", file.file, true);
            req.send();
        } else {
            const img = new Image();
            img.src = file.file;
            img.onload = () => onSuccess(img);
            img.onerror = onError;
        }
    }
}